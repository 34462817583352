import React from "react"

import {
  featherIconsMap,
  IconProps,
  IconSize,
  IconType,
} from "./feather-icon-map"
import * as styles from "./index.module.scss"

const sizeMapping = {
  small: { width: 16, height: 16 },
  medium: { width: 18, height: 18 },
  large: { width: 20, height: 20 },
  xlarge: { width: 24, height: 24 },
} as const satisfies Record<IconSize, { width: number; height: number }>

export interface Props extends IconProps {
  type: IconType
  className?: string
}

function FeatherIcon({
  type,
  disabled,
  className,
  size,
  color,
  ...otherProps
}: Props) {
  const sizeProps = size ? sizeMapping[size] : {}

  const Component = featherIconsMap[type]

  return (
    <Component
      className={className}
      style={{ color: disabled ? styles.disabled : color }}
      {...otherProps}
      {...sizeProps}
    />
  )
}

export default FeatherIcon
