import { Link } from "gatsby"
import * as React from "react"

import { getUrlWithTrailingSlash } from "../../shared-components/seo/canonical-tag/utils"
import * as analytics from "../../utils/analytics"
import { isInternalUrl } from "../../utils/anchor-links"
import { LocationState } from "../../utils/contexts/page-context"
import FeatherIcon from "../feather-icon"
import { IconType } from "../feather-icon/feather-icon-map"
import Loading from "../loading"
import * as styles from "./index.module.scss"

type BaseButtonProps = React.HTMLAttributes<HTMLButtonElement>

type SubmitButtonProps = BaseButtonProps & {
  type: "submit"
  as?: "button" // Not used, but needed for TypeScript to autodetect type when using component
}

type SimpleButtonProps = BaseButtonProps & {
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void
  type?: "button"
  as?: "button"
}

type AnchorProps = React.HTMLAttributes<HTMLAnchorElement> & {
  href: string
  target?: string
  rel?: string
  as: "a"
}

export type ButtonProps = {
  children: React.ReactNode
  size?: "medium" | "large"
  fullWidth?: boolean
  variant?: "primary" | "secondary" | "tertiary" | "link" | "unstyled"
  disabled?: boolean
  loading?: boolean
  state?: LocationState
  trackingProperties?: Record<string, unknown>
  iconLeft?: IconType
  iconRight?: IconType
} & (SubmitButtonProps | SimpleButtonProps | AnchorProps)

const Button = ({
  size = "large",
  fullWidth = false,
  disabled = false,
  loading = false,
  variant = "primary",
  children,
  className,
  state = {},
  trackingProperties,
  iconLeft,
  iconRight,
  ...otherProps
}: ButtonProps) => {
  const renderChildren = () => {
    if (iconLeft || iconRight) {
      return (
        <span className={styles.iconWrapper}>
          {iconLeft && <FeatherIcon size="large" type={iconLeft} />}
          {children}
          {iconRight && <FeatherIcon size="large" type={iconRight} />}
        </span>
      )
    }

    return children
  }

  const sharedProps = {
    disabled,
    className: `${styles.button} 
      ${styles[size]} 
      ${styles[variant]} 
      ${className} 
      ${fullWidth && styles.fullWidth} 
      ${disabled && styles.disabled} 
      ${loading && styles.loading}`,
    children: loading ? (
      <>
        <div className={styles.loadingContainer}>
          <Loading
            size={size}
            color={variant === "primary" ? "light" : "dark"}
          />
        </div>
        Loading
      </>
    ) : (
      renderChildren()
    ),
  }

  const augmentedOnClick =
    <T extends React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>>(
      onClickProp?: (e: T) => void,
    ) =>
    (e: T) => {
      onClickProp?.(e)
      if (trackingProperties) {
        analytics.buttonClicked(trackingProperties)
      }
    }

  if (!("href" in otherProps)) {
    return (
      <button
        {...otherProps}
        {...sharedProps}
        onClick={augmentedOnClick(otherProps.onClick)}
        // It has a type, but lint requires it to be a static string
        // eslint-disable-next-line react/button-has-type
        type={otherProps.type ?? "button"}
      />
    )
  }

  if (isInternalUrl(otherProps.href)) {
    return (
      <Link
        {...sharedProps}
        {...otherProps}
        state={state}
        role="link"
        to={getUrlWithTrailingSlash(otherProps.href)}
        onClick={augmentedOnClick(otherProps.onClick)}
      />
    )
  }

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <a
      rel="noopener noreferrer"
      role="link"
      {...sharedProps}
      {...otherProps}
      onClick={augmentedOnClick(otherProps.onClick)}
      tabIndex={0}
    >
      {sharedProps.children}
    </a>
  )
}

export default Button
