import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react"
import Statsig from "statsig-js"
import Cookies from "universal-cookie"

import { EXPERIMENT_UID_COOKIE } from "../constants"
import { getStatsigUser } from "../middleware/statsig-user-custom-data"
import { STATSIG_TIER } from "../utils"

type StatsigContextType = {
  isInitialized: boolean
  setIsInitialized?: (value: boolean) => void
}

const StatsigContext = createContext<StatsigContextType>({
  isInitialized: false,
})

type Props = {
  children: React.ReactNode
}

export const StatsigProvider = ({ children }: Props) => {
  const [isInitialized, setIsInitialized] = useState(false)

  const cookies = new Cookies()
  const user = getStatsigUser(name => cookies.get(name))
  cookies.set(EXPERIMENT_UID_COOKIE, user.userID, { path: "/" })

  useEffect(() => {
    // We load Statsig asynchronously on the client side.
    // This way it doesn't block the page, but the info can be later used
    // when the page is visited through an internal link.
    if (!Statsig.initializeCalled()) {
      Statsig.initialize(
        process.env.GATSBY_STATSIG_CLIENT_API_KEY ?? "",
        user,
        {
          environment: {
            tier: STATSIG_TIER,
          },
          initCompletionCallback: () => {
            setIsInitialized(true)
          },
        },
      )
    }
  })

  const value = useMemo(
    () => ({ isInitialized, setIsInitialized }),
    [isInitialized],
  )
  return (
    <StatsigContext.Provider value={value}>{children}</StatsigContext.Provider>
  )
}

export const useStatsigInitialized = () => {
  return useContext(StatsigContext)
}
