import * as React from "react"
import { useImperativeHandle, useRef } from "react"

import { useRetainHtmlInputValue } from "../../utils/hooks/use-retain-html-input-value"
import FeatherIcon from "../feather-icon"
import * as styles from "./index.module.scss"

export type InputProps = {
  variant?: "large" | "xmedium" | "medium"
  invalid?: boolean
  prefix?: () => React.JSX.Element
  "data-testid"?: string
  className?: string
  clearable?: boolean
  onAfterClearClick?: () => void
} & (
  | {
      setInputValue: (value: string) => void
      id: string
    }
  | { setInputValue?: never }
) &
  Omit<
    React.DetailedHTMLProps<
      React.InputHTMLAttributes<HTMLInputElement>,
      HTMLInputElement
    >,
    "size" | "prefix" | "suffix" | "ref"
  >

const Input = React.forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const {
    variant = "large",
    className,
    invalid = false,
    setInputValue,
    prefix,
    clearable,
    onAfterClearClick,
    value,
    onChange,
    ...otherProps
  } = props

  useRetainHtmlInputValue({ htmlId: otherProps.id, setInputValue })
  const innerRef = useRef<HTMLInputElement>(null)
  useImperativeHandle(ref, () => innerRef.current as HTMLInputElement)

  return (
    <div className={styles.inputWrapper}>
      {prefix && <div className={styles.prefix}>{prefix()}</div>}
      <input
        ref={innerRef}
        className={`${styles.input} ${styles[variant]} ${
          invalid && styles.invalid
        } ${prefix && styles.inputPrefixed} ${className}`}
        value={value}
        onChange={onChange}
        {...otherProps}
      />
      {clearable && value && (
        <FeatherIcon
          type="x-circle"
          className={styles.clearIcon}
          onClick={() => {
            onChange?.({
              target: { value: "" },
            } as React.ChangeEvent<HTMLInputElement>)
            innerRef.current?.focus()
            setTimeout(() => {
              onAfterClearClick?.()
            }, 0)
          }}
        />
      )}
    </div>
  )
})

Input.displayName = "Input"

export default Input
