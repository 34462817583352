import { createContext, useContext } from "react"

import {
  BucketValidCombination,
  CalculatedExperimentsWithParameters,
} from "../types"
import { Pathname } from "../types/base-paths"
import {
  getExperimentParametersByCombinationAndPath,
  getFallbackParametersAllExperiments,
  matcherMatchesPath,
} from "../utils"

const NON_MATCHING_PATH = "/non-matching-path"

export type ExperimentContextType<T extends Pathname> = {
  combination: BucketValidCombination<T>
  userId: string | undefined
  pathname?: Pathname
  basePath: T
}

export const ExperimentContext = createContext<ExperimentContextType<Pathname>>(
  {
    combination: {} as BucketValidCombination<Pathname>,
    userId: undefined,
    basePath: NON_MATCHING_PATH,
  },
)

/**
 * @param pathToEnableExperiment A string path or an array of paths on which the experiment should be enabled. Accepts wildcards "*" for any path part, and "**" for any path.
 * @returns An object with the experiment parameters for the experiments. If the path does not match the current path, it will return the control values for all experiments.
 */
export const useExperimentContext = <T extends Pathname>(
  pathsToEnableExperiment: T | T[],
): CalculatedExperimentsWithParameters<T> => {
  const pathsToEnable = [pathsToEnableExperiment].flat()
  const contextValues = useContext(ExperimentContext)

  const pathMatchesContext = (uknownContextValues: {
    basePath: Pathname
  }): uknownContextValues is ExperimentContextType<T> =>
    pathsToEnable.some(pathToEnableExperiment =>
      matcherMatchesPath(pathToEnableExperiment, uknownContextValues.basePath),
    )

  const parameters = getExperimentParametersByCombinationAndPath(
    contextValues.combination,
  )

  // For safety, this will return every experiment with control values for non-applicable ones,
  // but the TS type will only allow reflect the experiments available on the current path
  const fallbackParameters = getFallbackParametersAllExperiments<T>()

  return {
    ...fallbackParameters,
    ...(pathMatchesContext(contextValues) ? parameters : {}),
  }
}
