import React from "react"

import { isObject, isString } from "../../../utils/types"

type Primitive = string | undefined | boolean | number

type Props = {
  object: DeepDictionary<Primitive>
  "data-testid"?: string
}

const ObjectEntriesDisplay = ({ object, "data-testid": dataTestId }: Props) => {
  return (
    <div data-testid={`object-entries-${dataTestId}`}>
      {Object.entries(object).map(([key, value]) => {
        const stringValue = isString(value) ? value : JSON.stringify(value)
        return (
          <div key={key}>
            <strong>{key}</strong>:{" "}
            {isObject(value) ? (
              <div style={{ paddingLeft: "2rem" }}>
                <ObjectEntriesDisplay
                  object={value}
                  data-testid={`${dataTestId}-${key}`}
                />
              </div>
            ) : (
              stringValue
            )}
          </div>
        )
      })}
    </div>
  )
}

export default ObjectEntriesDisplay
