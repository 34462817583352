// extracted by mini-css-extract-plugin
export var centeredTitle = "cZ_hY";
export var childrenContainer = "cZ_h0";
export var container = "cZ_h";
export var dark = "cZ_c3";
export var innerContainer = "cZ_cN";
export var left = "cZ_cC";
export var light = "cZ_c1";
export var noBottomPadding = "cZ_hT";
export var noRectangle = "cZ_hX";
export var noTopPadding = "cZ_hV";
export var overflowHidden = "cZ_hW";
export var right = "cZ_cD";
export var subtitle = "cZ_s";
export var tan = "cZ_cy";
export var textContainer = "cZ_ck";
export var textContainerCustomWidth = "cZ_hZ";