export const isBrowser = () => typeof window !== "undefined"

export const DATADOG_USER_AGENT = "DatadogSynthetics"
export const isDataDogEnvironment = () =>
  isBrowser() && window.navigator.userAgent.includes(DATADOG_USER_AGENT)

export const isProduction = () => process.env.GATSBY_ENV === "production"

export const isCypress = () =>
  isBrowser() && "Cypress" in window && window.Cypress
