// extracted by mini-css-extract-plugin
export var border1 = "cY_hM";
export var border2 = "cY_hN";
export var border3 = "cY_hP";
export var border4 = "cY_hQ";
export var container = "cY_h";
export var dark = "cY_c3";
export var large = "cY_b9";
export var ldsRing = "cY_hR";
export var light = "cY_c1";
export var medium = "cY_cb";
export var primaryPressed = "cY_hS";
export var xlarge = "cY_hL";