import React from "react"

import * as styles from "./index.module.scss"

export type HtmlHeadingTag = `h${1 | 2 | 3 | 4 | 5 | 6}`

interface Props extends React.HTMLAttributes<HTMLHeadingElement> {
  as: HtmlHeadingTag
  variant?: "1" | "2"
  textColor?: "white" | "none"
}

function Title({
  as,
  variant = "1",
  className,
  textColor = "none",
  ...rest
}: Props) {
  const Element = as

  return (
    <Element
      className={`${styles.title} ${styles[`variant${variant}`]} ${
        textColor === "white" ? styles.titleWhite : ""
      } ${className}`}
      {...rest}
    />
  )
}

export default Title
